/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import "../css/app.css";

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
import $ from "jquery";

import dt from "datatables.net";

import 'bootstrap';

$('.littleImage').click(function () {
    var bigImage = $('.bigImage');
    var littleImage = $(this);
    var linkBigImage = bigImage.attr('src');
    var linkLittleImage = littleImage.attr('src');

    bigImage.attr('src', linkLittleImage);
    littleImage.attr('src', linkBigImage);
});

$(document).ready(function () {
    /**
     * Dynamic category and subCategory in easy admin occasion
     */

    var $category_occasion = $("#category");
    var $token_occasion = $("#_token");


    $category_occasion.change(function () {
        var $form = $(this).closest('form');
        var data = {};

        data[$token_occasion.attr("name")] = $token_occasion.val();
        data[$category_occasion.attr("name")] = $category_occasion.val();

        $.post($form.attr("action"), data).then(function (response) {
            $("#subCategory").replaceWith($(response).find("#subCategory"));
        });
    });

    $('[data-toggle="tooltip"]').tooltip({
        container: 'body'
    });


    $('#home_table').dataTable({
        "paging": false,
        "info": false,
        "searching": false,
    });

});
  
  
  